
import { useRoutes } from 'react-router-dom';
import { AuthProvider } from './contexts/JWTAuthContext';
// import { CssBaseline } from '@mui/material';//   
// import { MatxTheme } from './components';//
// import { SettingsProvider } from './contexts/SettingsContext';//
import routes from './routes';
import React from 'react';

const App = () => {
  const content = useRoutes(routes);

  return (
    <AuthProvider>
      {/* <SettingsProvider>
        <MatxTheme>
          <CssBaseline /> */}
          {content}
        {/* </MatxTheme>
      </SettingsProvider> */}
    </AuthProvider>
  );
};

export default App;
