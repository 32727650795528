import React from 'react';
import { Box} from '@mui/material';

const BridgeLogo = (image) => {
  return (
    <Box
      component="img"
      width="150px"
      height="50px"
      alt="The house from the offer."
      src={ image?.avatar[0]?.dataURL || "/assets/images/logos/BridgeLogo.png" }
    />
  );
};

export default BridgeLogo;
