import React from 'react';
import { createContext, useState, useEffect } from 'react';
import { merge } from 'lodash';
import { MatxLayoutSettings } from 'app/components/MatxLayout/settings';

import axios from 'axios';
// --------------------------------start --------------------------------
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};
// -----------------------------------send request --------------------------------

const SettingsContext = createContext({
  settings: MatxLayoutSettings,
  initSettings: () => {},
  updateSettings: () => {},
  switchServiceId: () => {}
});

export const SettingsProvider = ({ settings, children }) => {

  const [currentSettings, setCurrentSettings] = useState(settings || MatxLayoutSettings);

  const handleUpdateSettings = (update = {}) => {
    const marged = merge({}, currentSettings, update);
    setCurrentSettings(marged);
  };

  // 2023-11-14 Getting company information

  const getCompanyInfo = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      setSession(accessToken);

      const res = await axios.get(`${process.env.REACT_APP_API_PATH}/operatingmanage/servicemanagement`);
      const data = {companyinfo:res.data.rows[0]};
      handleUpdateSettings(data);
    } catch (err) {
      console.log(err);
    }
  }

  const switchServiceId = async (row) =>{
    try {
      const accessToken = localStorage.getItem("accessToken");
      setSession(accessToken);

      const res = await axios.post(`${process.env.REACT_APP_API_PATH}/operatingmanage/servicemanagement`, row);
      const data = {companyinfo:res.data.rows[0]}
      handleUpdateSettings(data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getCompanyInfo();
  }, []);
  // end getting company information

  return (
    <SettingsContext.Provider
      value={{ 
        settings: currentSettings, 
        initSettings: getCompanyInfo,
        updateSettings: handleUpdateSettings ,
        switchServiceId
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;
