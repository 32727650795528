import React from 'react';
import { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import jwtDecode from "jwt-decode";
import { MatxLoading } from 'app/components';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};

const isValidToken = (accessToken) => {
  if (!accessToken) return false;

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'GenerateOTP': {
      const { data } = action.payload;
      return data;
    }
    
    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    case 'REGISTER': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => {},
  forgotpassword: () => {},
  resetPassword: () => {},
  forgotusername: () => {},
  verifyOTP: () => {},
  logout: () => {},
  register: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const register = async (email, username, password) => {
    const response = await axios.post(`${process.env.REACT_APP_API_PATH}/auth/register`, { email, username, password});
    
    const { user } = response.data;
    if(response.status === 201){
      dispatch({ type: 'REGISTER', payload: { user } });
    } else {
      // add error toast
    }
  };

  const login = async (username, password) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_PATH}/auth/login`, { username, password });
      const { user , accessToken } = response.data;

      if(response.status === 201){
        setSession(accessToken)
        dispatch({ type: 'LOGIN', payload: { user } });
        return ({status:response.status, msg:""});
      } else {
        return ({status:response.status, msg:response.data.error});
      }
    } catch (error) {
      // toast('Here is your toast.');
      return ({status: error.response.status, msg:error.response.data.error});
    }
  };

  const generateOTP = async (username) => {
    try {
      let {
        data: { email },
      } = await axios.get(`${process.env.REACT_APP_API_PATH}/auth/user/${username}`);

      const { status } =  await axios.post(`${process.env.REACT_APP_API_PATH}/auth/generateOTP`, { username });
      // dispatch({ type: 'LOGIN', payload: { user } });
      // send mail with the OTP
      if (status === 201) {
        let text = `Your Password Recovery OTP is XXXX. Verify and recover your password.`;
        await axios.post(`${process.env.REACT_APP_API_PATH}/mail/registerMail`, {
          username,
          userEmail: email,
          text,
          subject: "Password Recovery OTP",
        });
      }
      // dispatch({ type: 'LOGIN', payload: { user } });
    } catch (error) {

    }
  };

  const forgotpassword = async (username, password, newPassword) => {
    const response = await axios.post(`${process.env.REACT_APP_API_PATH}/auth/forgotpassword`, { username, password, newPassword });
    if(response.status === 201){
      dispatch({ type: 'ForgotPassword', payload: { user: null } });
      return ({status: true, username});
    } else {
      return ({status: false , username});
    }
  };

  const resetPassword = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_API_PATH}/auth/resetPassword`, { data });
    if(response.status === 201){
      dispatch({ type: 'ForgotPassword', payload: { user: null } });
      return ({status: true});
    } else {
      return ({status: false});
    }
  };
  
  const forgotusername = async (email) => {
    const response = await axios.post(`${process.env.REACT_APP_API_PATH}/auth/forgotusername`, {email});
    const { user } = response.data;

    dispatch({ type: 'ForgotUsername', payload: { user } });
  };
  
  const verifyOTP = async (otp) => {
    const response = await axios.post(`${process.env.REACT_APP_API_PATH}/auth/verifyOTP`, {otp});
    const { data } = response.data;
    if(response.status === 201){
      dispatch({ type: 'ForgotUsername', payload: { user: null } });
      return ({status: true, data});
    } else {
      return ({status: false, data});
    }
  };

  const logout = () => {
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");

        if(isValidToken(accessToken)){
          const decode = jwtDecode(accessToken);
          const username = decode.username;
          setSession( accessToken );
          const { data } = await axios.get(`${process.env.REACT_APP_API_PATH}/auth/user/${username}`);
          dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: data } });
        }else{
          dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
        }
      } catch (err) {
        dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
      }
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider 
      value={{ 
        ...state, 
        method: 'JWT', 
        login, 
        generateOTP,
        forgotpassword, 
        resetPassword,
        forgotusername, 
        verifyOTP,
        logout, 
        register 
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
