import React from 'react';
import { Box} from '@mui/material';

const ServiceLogo = (image) => {
  return (
    <Box
      component="img"
      width="40px"
      height="40px"
      alt="The house from the offer."
      src={image?.avatar[0]?.dataURL || "/assets/images/logos/BridgeLogo.png" }
    />
  );
};

export default ServiceLogo;
