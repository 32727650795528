import React from 'react';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';

// 
import { MatxTheme } from './components';
import { SettingsProvider } from './contexts/SettingsContext';
import { socket, SocketContext } from 'app/contexts/SocketContext';
import { CssBaseline } from '@mui/material';
// 

import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import ViewsRoots from 'app/views/ViewsRoots';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/views/sessions/JwtRegister')));
const ForgotPassword = Loadable(lazy(() => import('app/views/sessions/ForgotPassword')));
const ForgotUsername = Loadable(lazy(() => import('app/views/sessions/ForgotUsername')));
const VerifyOTP = Loadable(lazy(() => import('app/views/sessions/VerifyOTP')));
const ResetPassword = Loadable(lazy(() => import('app/views/sessions/ResetPassword')));
// --------------------------- LIFF APP ---------------------------
const MainLIFF = Loadable(lazy(() => import('app/views/llif-app/MainLIFF')));
const LiffApp = Loadable(lazy(() => import('app/views/llif-app/LiffApp')));
const LIFFQuestion = Loadable(lazy(() => import('app/views/llif-app/LIFFQuestion')));
const LIFFQThanks = Loadable(lazy(() => import('app/views/llif-app/LIFFQThanks')));
const PageBulder = Loadable(lazy(()=>import('app/views/llif-app/PageBulder')));
const AdsPageBulder = Loadable(lazy(()=>import('app/views/llif-app/AdsPageBulder')));
const LineLogin = Loadable(lazy(()=>import('app/views/llif-app/LineLogin')));
const LineAuthLogin = Loadable(lazy(()=>import('app/views/llif-app/LineAuthLogin')));
const RedirectNickNameLink = Loadable(lazy(()=>import('app/views/llif-app/RedirectNickNameLink')));

const LiffInit = Loadable(lazy(() => import('app/views/llif-app/LiffInit')));

const TermsOfService = Loadable(lazy(() => import('app/views/otherPage/TermsOfService')));
const PrivacyPolicy = Loadable(lazy(() => import('app/views/otherPage/PrivacyPolicy')));
const CompanyProfile = Loadable(lazy(() => import('app/views/otherPage/CompanyProfile')));

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));

const routes = [
  { path: '/termsOfService/:mainServiceId',    element: <TermsOfService /> },
  { path: '/privacyPolicy/:mainServiceId',    element: <PrivacyPolicy /> },
  { path: '/companyProfile/:mainServiceId',    element: <CompanyProfile /> },

  { path: '/nickName/:id',    element: <RedirectNickNameLink /> },
  { path: '/mem/main/:type/:id',    element: <MainLIFF /> },    // to manage message and chat in user
  { path: '/mem/main/:type/:id/:msgHistoryId',    element: <MainLIFF /> },    // to manage message and chat in user
  { path: '/mem/question/:questionId',    element: <LIFFQuestion /> },
  { path: '/mem/complete/:questionId',    element: <LIFFQThanks /> },
  { path: '/mem/pageBuilder/:pageType/:code',    element: <PageBulder /> },
  { path: '/mem/Liff',    element: <LiffApp /> },
  // { path: 'mem/:ads/:adscode',    element: <AdsPageBulder /> },
  { path: 'mem/:ads/:adscode',    element: <LineLogin /> },
  { path: '/line/auth_callback',    element: <LineAuthLogin /> },
  
  {
    element: (
      <AuthGuard>
        <SettingsProvider>
          <SocketContext.Provider value={socket}>
            <MatxTheme>
              <CssBaseline />
              <MatxLayout />
            </MatxTheme>
          </SocketContext.Provider>
        </SettingsProvider>
      </AuthGuard>
    ),
    children: [
      ...ViewsRoots,
      {
        path: '/dashboard/default',
        element: <Analytics />,
        auth: authRoles.admin
      }
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/verifyOTP/:username', element: <VerifyOTP /> },
  { path: '/session/forgot-username', element: <ForgotUsername /> },
  { path: '/session/change-password', element: <ForgotPassword /> },
  { path: '/session/resetPassword', element: <ResetPassword /> },

  { path: '/', element: <Navigate to="manageuser/userlist" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
