import { createContext } from 'react';
import socketIO from 'socket.io-client';

export const socket = socketIO.connect(
    process.env.REACT_APP_API_URL, {
      path: "/api/socket.io",
      transports: ["xhr-polling", "polling"],
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 5,
    }
  );
export const SocketContext = createContext(socket);
