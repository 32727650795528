import React from 'react';
import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

// const SellerManageGrid = Loadable(lazy(() => import('./fulltable/SellerManageGrid')));
const UserList = Loadable(lazy(() => import('./manageuser/manageuserlist/UserList')));
const GroupMain = Loadable(lazy(() => import('./manageuser/manageusergroup/GroupMain')));
const UserRankMain = Loadable(lazy(() => import('./manageuser/manageuserrank/UserRankMain')));
const UserLevel = Loadable(lazy(() => import('./manageuser/manageuserlevel/UserLevel')));
// 
const LineLinkage = Loadable(lazy(() => import('./manageline/LineLinkage')));
const LineLinkedImport = Loadable(lazy(() => import('./manageline/LineLinkedImport')));
const LineUserDistribustion = Loadable(lazy(() => import('./manageline/LineUserDistribustion')));
const LineDeliveryCountReport = Loadable(lazy(() => import('./manageline/LineDeliveryCountReport')));
const LineSwitchingHistory = Loadable(lazy(() => import('./manageline/LineSwitchingHistory')));
const RichMenuSetting = Loadable(lazy(() => import('./manageline/RichMenuSetting')));
const ManageLineNotifyTemp = Loadable(lazy(() => import('./manageLineNotification/ManageLineNotifyTemp')));
const ManageLineNotifyImageSet = Loadable(lazy(() => import('./manageLineNotification/ManageLineNotifyImageSet')));
// 
const ChatSupport =Loadable(lazy(() => import('./chat/chatsupport/ChatSupport')));
const ChatReceptionManagement =Loadable(lazy(() => import('./chat/ChatReceptionManagement')));
const ChatResponseTemplate =Loadable(lazy(() => import('./chat/responseTemplate/ChatResponseTemplate')));
const MassDeliveryChat =Loadable(lazy(() => import('./chat/delivery/MassDeliveryChat')));
const ChatAutomationScenario =Loadable(lazy(() => import('./chat/scenario/ChatAutomationScenario')));
const ChatDeliveryHistory =Loadable(lazy(() => import('./chat/history/ChatDeliveryHistory')));
const ChatResponseHistory =Loadable(lazy(() => import('./chat/ChatResponseHistory')));
const DeliveryStatementTemplate =Loadable(lazy(() => import('./chat/deliveryTemplate/DeliveryStatementTemplate')));
const ScheduledChatDelivery =Loadable(lazy(() => import('./chat/schedule/ScheduledChatDelivery')));
const SearchShortcuts =Loadable(lazy(() => import('./chat/shortcut/SearchShortcuts')));
// 231203--------------------------------------------------------------------------------------
const MessageAutomationScenario =Loadable(lazy(() => import('./message/scenario/index')));
const MassDeliveryMessage =Loadable(lazy(() => import('./message/delivery/index')));
const ScheduleDeliveryMessage =Loadable(lazy(() => import('./message/schedule/index')));
const ShortcutTemplate =Loadable(lazy(() => import('./message/shortcut/index')));
const DeliveryTemplate =Loadable(lazy(() => import('./message/template/index')));
const DeliverydMessageList =Loadable(lazy(() => import('./message/history/index')));
// 231204--------------------------------------------------------------------------------------
const DirectMessageAutomationScenario =Loadable(lazy(() => import('./directmessage/scenario/index')));
const MassDeliveryDirectMessage =Loadable(lazy(() => import('./directmessage/delivery/index')));
const ScheduleDeliveryDirectMessage =Loadable(lazy(() => import('./directmessage/schedule/index')));
const ShortcutTemplateDirectMessage =Loadable(lazy(() => import('./directmessage/shortcut/index')));
const DirectMessageDeliveryTemplate =Loadable(lazy(() => import('./directmessage/history/index')));
const ActionManage =Loadable(lazy(() => import('./contentsManage/action')));
const AnswerFormManage =Loadable(lazy(() => import('./answerform/formManage')));
const FreeWebPageManagement = Loadable(lazy(() => import('./contentsManage/freeWebPageManagement')));
const LandingPageManagement = Loadable(lazy(() => import('./contentsManage/landingPageManagement')));
const RedirectUrlListManagement = Loadable(lazy(() => import('./contentsManage/redirectUrlListManagement')));
const HtmlReferenceStyleSummary = Loadable(lazy(() => import('./contentsManage/htmlReferenceStyleSummary')));
// 
const AdsAgencyManagement = Loadable(lazy(() => import('./advertisingManagement/agencyManagement')));
const AdsMediaManagement = Loadable(lazy(()=>import('./advertisingManagement/mediaManagement')));
const AdsGenreManagement = Loadable(lazy(()=>import('./advertisingManagement/adsGenreManagement')));
const AdsCodeManagement = Loadable(lazy(()=>import('./advertisingManagement/adsCodeManagement')));
const FileManage = Loadable(lazy(() => import('./fileManagement/fileManage')));
// 231228===============================================================================
const ServiceManagement = Loadable(lazy(() => import('./operatingmanage/Management')));
// 231228===============================================================================
const StaffManagement = Loadable(lazy(() => import('./operatingmanage/StaffManagement')));
// 240320===============================================================================
const Aggregation = Loadable(lazy(() => import('./aggregation/aggregation')));
const AggregationOfAdvertisingEffect = Loadable(lazy(() => import('./aggregation/aggregationOfAdvertisingEffect')));
const MessageScenarioDeliveryEffects = Loadable(lazy(() => import('./aggregation/messageScenarioDeliveryEffects')));
const DirectScenarioDistributionEffect = Loadable(lazy(() => import('./aggregation/directScenarioDistributionEffect')));
const FreeWebpageEffects = Loadable(lazy(() => import('./aggregation/freeWebpageEffects')));
const RedirectURLEffects = Loadable(lazy(() => import('./aggregation/redirectURLEffects')));
const CustomActionEffects = Loadable(lazy(() => import('./aggregation/customActionEffects')));

const ViewsRoots = [
  { path: '/manageuser',                            element: <UserList /> },
  { path: '/manageuser/userlist',                   element: <UserList /> },
  { path: '/manageuser/groupsetting',               element: <GroupMain /> },
  { path: '/manageuser/userrank',                   element: <UserRankMain /> },
  { path: '/manageuser/userlevel',                  element: <UserLevel /> },
  // 
  { path: '/manageline',                            element: <LineLinkage /> },
  { path: '/lineimport',                            element: <LineLinkedImport /> },
  { path: '/lineuserdistribution',                  element: <LineUserDistribustion /> },
  { path: '/linedeliverycountreport',               element: <LineDeliveryCountReport /> },
  { path: '/lineswitchinghistory',                  element: <LineSwitchingHistory /> },
  { path: '/richmenusetting',                       element: <RichMenuSetting /> },
  // 
  { path: '/manageLineNotifyTemp',                  element: <ManageLineNotifyTemp /> },
  { path: '/manageLineNotifyImageSet',              element: <ManageLineNotifyImageSet /> },
  // 
  { path: '/chat/response/screen',                  element: <ChatSupport /> },
  { path: '/chat/reception/management',             element: <ChatReceptionManagement /> },
  { path: '/chat/response/history',                 element: <ChatResponseHistory /> },
  { path: '/chat/response/template',                element: <ChatResponseTemplate /> },
  { path: '/chat/automation/scenario',              element: <ChatAutomationScenario /> },
  { path: '/chat/broadcast',                        element: <MassDeliveryChat /> },
  { path: '/chat/scheduled/delivery',               element: <ScheduledChatDelivery /> },
  { path: '/chat/search/shortcut',                  element: <SearchShortcuts /> },
  { path: '/chat/delivery/template',                element: <DeliveryStatementTemplate /> },
  { path: '/chat/delivery/history',                 element: <ChatDeliveryHistory /> },
  // 231203----------------------------------------------------------
  { path: '/message/automation/scenario',           element: <MessageAutomationScenario /> },
  { path: '/mass/delivery/message',                 element: <MassDeliveryMessage /> },
  { path: '/schedule/delivery/message',             element: <ScheduleDeliveryMessage /> },
  { path: '/message/search/shortcut',               element: <ShortcutTemplate /> },
  { path: '/message/delivery/template',             element: <DeliveryTemplate /> },
  { path: '/message/delivery/history/list',         element: <DeliverydMessageList /> },
  // direct message
  { path: '/directmessge/automation/scenario',      element: <DirectMessageAutomationScenario /> }, 
  { path: '/mass/delivery/directmessage',           element: <MassDeliveryDirectMessage /> },
  { path: '/schedule/delivery/directmessge',        element: <ScheduleDeliveryDirectMessage /> },
  { path: '/directmessge/search/shortcut',          element: <ShortcutTemplateDirectMessage /> },
  { path: '/directmessge/delivery/history/list',    element: <DirectMessageDeliveryTemplate /> },
  // 231204----------------------------------------------------------------------------------------
  { path: '/contentsManage/action',                 element: <ActionManage /> },
  { path: '/answer/form/management',                element: <AnswerFormManage /> },       //回答フォーム管理
  { path: '/freeWebPageManagement',                 element: <FreeWebPageManagement /> }, 
  { path: '/landingPageManagement',                 element: <LandingPageManagement /> }, 
  { path: '/redirectUrlList',                       element: <RedirectUrlListManagement /> }, 
  { path: '/htmlReferenceStyleSummary',             element: <HtmlReferenceStyleSummary /> }, 
  // 
  { path: '/adsAgencyManagement',                   element: <AdsAgencyManagement /> }, 
  { path: '/adsMediaManagement',                    element: <AdsMediaManagement /> }, 
  { path: '/adsGenreManagement',                    element: <AdsGenreManagement /> }, 
  { path: '/adsCodeManagement',                     element: <AdsCodeManagement /> }, 
  { path: '/fileManage',                            element: <FileManage /> }, 
  { path: '/serviceManagement',                     element: <ServiceManagement /> },
  { path: '/staffManagement',                       element: <StaffManagement /> },
  // 
  { path: '/aggregationA',                                    element: <Aggregation /> },
  { path: '/aggregation/advertisingEffect',                   element: <AggregationOfAdvertisingEffect /> },
  { path: '/aggregation/messageScenarioDeliveryEffects',      element: <MessageScenarioDeliveryEffects /> },
  { path: '/aggregation/directScenarioDistributionEffect',    element: <DirectScenarioDistributionEffect /> },
  { path: '/aggregation/freeWebpage',                         element: <FreeWebpageEffects /> },
  { path: '/aggregation/redirectURL',                         element: <RedirectURLEffects /> },
  { path: '/aggregation/customAction',                        element: <CustomActionEffects /> },
];

export default ViewsRoots;
